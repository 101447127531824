import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topgun',
  templateUrl: './topgun.component.html',
  styleUrls: ['./topgun.component.scss']
})
export class TopgunComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    twttr.widgets.load();
  }

}
