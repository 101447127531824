import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hunger',
  templateUrl: './hunger.component.html',
  styleUrls: ['./hunger.component.scss']
})
export class HungerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    twttr.widgets.load();
  }

}
