import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from "lodash";

@Injectable()
export class MessageService {

  constructor(
    public toastr: ToastrService
  ) { }

  successMessage(message) {
    console.log("message::::",message);
    this.toastr.success(message);
  }

  errorMessage(message) {
    this.toastr.error(message);
  }

  showErrorMessages(errors) {
    console.log(errors.error);
    let self = this;
    if(typeof errors.error == 'undefined'){
      if (errors.length) {
        _.forEach(errors, function (err) {
          if (err.error) {
            self.errorMessage(err.error);
          } else {
            self.errorMessage(err.message ? err.message : err.msg);
          }
        });
      } else {
        self.errorMessage("Invalid Response");
      }
    }else{
      var errorData = errors.error.error;
      if (errorData.length) {
        _.forEach(errorData, function (err) {
          if (err.error) {
            self.errorMessage(err.error);
          } else {
            self.errorMessage(err.message ? err.message : err.msg);
          }
        });
      } else {
        self.errorMessage("Invalid Response");
      }
    }

  }
}
