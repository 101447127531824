import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService, CompanyService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import $ from "jquery";
import { flatten } from '@angular/compiler';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent {
  title = 'my-app';
  getAllCountryFromServer: any = [];
  getAllStateFromServer: any = [];
  getAllTimeZoneFromServer:any = [];
  addCompanyform: FormGroup;
  addCompanyObject: any = {};
  addCompanyformStatus: boolean = false;
  checkSubmitStatus: boolean = false;
  paramData: any;
  getCpId: any; 
  isEdit: boolean = false;
  submitted : any;
  moduleStatusName: string = 'Create Company';
  public mask = [ /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private companyService: CompanyService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      this.paramData = params
     
        this.isEdit = false;
        this.moduleStatusName = 'Create Company';
        this.getCPID();
        this.addCompanyObject = { solution:"", name : '', cpid : '', address:'', countryGuid:'', countryCode:'', contactNo:'', timezoneGuid: '', firstName: '', lastName: '', userId: ''}
     
    });
    this.createFormControls();
  }

  ngOnInit() {
    this.getAllCountry();
    this.getAllTmezone();


    // setTimeout(function () {
    //   $(document).ready(function () {

    //     $('.team').slick({
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         autoplay: false,
    //         arrows: true,
    //         responsive: [
    //             {
    //                 breakpoint: 991,
    //                 settings: {
    //                     slidesToShow: 2,
    //                 }
    //             },
    //             {
    //                 breakpoint: 767,
    //                 settings: {
    //                     slidesToShow: 1
    //                 }
    //             },
    //             {
    //                 breakpoint: 480,
    //                 settings: {
    //                     slidesToShow: 1,
    //                 }
    //             }
    //         ]
    //     });
        
    //     $('.fun').slick({
    //         dots: false,
    //         infinite: true,
    //         speed: 500,
    //         fade: true,
    //         cssEase: 'linear'
    //     });
    
    //     $('#default-demo').slickLightbox();
    
    // });
    // },1000)
  

// $(window).scroll(function () {
//     if ($(this).scrollTop() > 250) {
//         $('header').addClass("sticky");
//     }
//     else {
//         $('header').removeClass("sticky");
//     }
// });

  }

  ngAfterViewInit(): void {
    // @ts-ignore
    twttr.widgets.load();
  }

  getAllCountry() {
    this.spinner.show();
    this.companyService.getCountry().subscribe(response => {
      this.getAllCountryFromServer = response.data;
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.messageService.showErrorMessages(error);
      }
    );
  }

  getAllTmezone() {
    this.spinner.show();
    this.companyService.getTimezone().subscribe(response => {
      this.getAllTimeZoneFromServer = response.data;
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.messageService.showErrorMessages(error);
      }
    );
  }

  getCPID() {
    this.spinner.show();
    this.companyService.getCPId().subscribe(response => {
      this.getCpId = response.data;
      this.addCompanyObject.cpid = this.getCpId;
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.messageService.errorMessage(error);
      }
    );
  }

  createFormControls() {
    this.addCompanyform = new FormGroup({
      name: new FormControl('', [Validators.required]),
      cpid: new FormControl('', Validators.required),
      solution: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      countryGuid: new FormControl('', Validators.required),
      contactNum: new FormControl('', [Validators.required, Validators.minLength(10)]),
      countryCode: new FormControl('', Validators.required),
      timezoneGuid: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      userId: new FormControl('', Validators.required),
    });
  }

  resetForm(){
    this.submitted = false;
  }

  addCompany(){
    this.addCompanyformStatus = true;
    this.submitted = true;
      if (this.addCompanyform.status === 'VALID') {
        this.spinner.show();
        var contactNum = this.addCompanyObject.contactNum.replace(/\-/g, "");
        this.addCompanyObject.contactNo = this.addCompanyObject.countryCode+"-"+contactNum
        this.companyService.saveCompany(this.addCompanyObject).subscribe(response =>{
          this.messageService.successMessage(response.message);
          this.spinner.hide();
          $('.closemodal').click();
          this.addCompanyObject = {solution:'', name : '', cpid : '', address:'', countryGuid:'', countryCode:'', contactNo:'', timezoneGuid: '', firstName: '', lastName: '', userId: ''}
          // $("myModal").model("hide");
          this.router.navigate(['/']);
        },
        error => {
          this.spinner.hide();
          this.messageService.showErrorMessages(error);
        });
      }
  }

  cancelAddCompanyform (){
    this.router.navigate(['/']);
  }
}
