import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvengersComponent } from './components/avengers/avengers.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CoreComponent } from './components/core/core.component';
import { DarkknightComponent } from './components/darkknight/darkknight.component';
import { GladiatorComponent } from './components/gladiator/gladiator.component';
import { GodfatherComponent } from './components/godfather/godfather.component';
import { HungerComponent } from './components/hunger/hunger.component';
import { InterstellarComponent } from './components/interstellar/interstellar.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { MoonwalkerComponent } from './components/moonwalker/moonwalker.component';
import { SherlockComponent } from './components/sherlock/sherlock.component';
import { SkyfallComponent } from './components/skyfall/skyfall.component';
import { SpectreComponent } from './components/spectre/spectre.component';
import { TopgunComponent } from './components/topgun/topgun.component';
import { TranscendenceComponent } from './components/transcendence/transcendence.component';
import { ExpendablesComponent } from './components/expendables/expendables.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: DashboardComponent },
  { path: 'cable-fault-detection', component: AvatarComponent },
  { path: 'smart-greenhouse', component: AvengersComponent },
  { path: 'smart-inhaler-solution', component: CoreComponent },
  { path: 'connected-parking', component: DarkknightComponent },
  { path: 'smart-warehouse', component: GladiatorComponent },
  { path: 'facility-monitoring-system', component: GodfatherComponent },
  { path: 'plant-disease-monitoring', component: HungerComponent },
  { path: 'smart-refinery-monitoring', component: InterstellarComponent },
  { path: 'the-water-project', component: MatrixComponent },
  { path: 'smart-water-tank', component: MoonwalkerComponent },
  { path: 'environment-control-monitoring', component: SherlockComponent },
  { path: 'air-quality-monitoring', component: SkyfallComponent },
  { path: 'smart-parking', component: SpectreComponent },
  { path: 'smart-food-monitoring', component: TopgunComponent },
  { path: 'counterfeit-detection', component: TranscendenceComponent },
  { path: 'smart-aqua-water', component: ExpendablesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
