import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { MessageService } from './message.service';

@Injectable()
export class HttpRequestService {
  constructor(
    private http: HttpClient,
    private messageService : MessageService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone();
    return next.handle(request).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {


        }
    },(err: any) => {
        console.log('HTTPERROR',err);
        if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
            }else if(err.status === 500){
              this.messageService.showErrorMessages(err);
            }else {
              console.log('dfsdfdffffffffffffffff')
              this.messageService.showErrorMessages(err);
            }
        }
    });
}

  GET(url, bearerTokenRequired, params?: any): Observable<any> {
    console.log('com hereeeeeeeeeeeeeeeedddd')
    let configHeader = {};
	  let parameter = {};
    if(bearerTokenRequired){
      configHeader = {
        headers: {
          'Content-Type': 'application/json',
        },
        observe: 'response'
      };
    }
    
    if(params){
      params.params.timestamp = Date.now();
      parameter = Object.assign(params, configHeader);
    }else{
      parameter = configHeader;
      parameter = Object.assign({params :{timestamp: Date.now()}}, configHeader);
    }
    console.log(url, parameter)
    return this.http.get<any>(url, parameter)
      .map(response => {
      console.log("TCL: HttpRequestService -> response", response)
        return response.body;
      });
  }

  POST(url, data, bearerTokenRequired, queryParameter?: any) {
    let configHeader = {};
    let parameter = {};
    if(bearerTokenRequired){
     configHeader = {
        headers: {
          'Content-Type': 'application/json',
        },
        observe: 'response'
      };
    }else{
      configHeader = {
        headers: {
          'Content-Type': 'application/json',
        },
        observe: 'response'
      };
    }
    
    if(queryParameter){
      parameter = Object.assign(queryParameter, configHeader);
    }else{
      parameter = configHeader;
    }

  return this.http.post<any>(url, data, parameter)
    .map(response => {
      return response.body;
    });
  }

}