import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs/Observable';
import { SITECONST } from './mainConfig';

@Injectable()
export class CompanyService {

  constructor(
    private httpRequestService: HttpRequestService
  ) { }


  saveCompany(data): Observable<any> {
    return this.httpRequestService.POST(`${SITECONST.SERVICE_API_URL}/api/v1.1/admin/company`, data, true).map((response) => {
      return response;
    })
  }

  getCountry(): Observable<any> {
    return this.httpRequestService.GET(`${SITECONST.SERVICE_API_URL}/api/v1.1/admin/country`, true).map((res) => {
      return res;
    })
  }

  getTimezone(): Observable<any> {
    return this.httpRequestService.GET(`${SITECONST.SERVICE_API_URL}/api/v1.1/admin/timezone/`, true).map((res) => {
      return res;
    })
  }

  getCPId(): Observable<any> {
    return this.httpRequestService.GET(`${SITECONST.SERVICE_API_URL}/api/v1.1/admin/company/cpid`, true).map((response) => {
      return response;
    })
  }
}
