import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { CompanyService, MessageService, HttpRequestService } from './services';
import { TextMaskModule } from 'angular2-text-mask';
import { OnlyNumberDirective } from './only-number.directive';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvengersComponent } from './components/avengers/avengers.component';
import { CoreComponent } from './components/core/core.component';
import { DarkknightComponent } from './components/darkknight/darkknight.component';
import { GladiatorComponent } from './components/gladiator/gladiator.component';
import { GodfatherComponent } from './components/godfather/godfather.component';
import { HungerComponent } from './components/hunger/hunger.component';
import { InterstellarComponent } from './components/interstellar/interstellar.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { MoonwalkerComponent } from './components/moonwalker/moonwalker.component';
import { SherlockComponent } from './components/sherlock/sherlock.component';
import { SkyfallComponent } from './components/skyfall/skyfall.component';
import { SpectreComponent } from './components/spectre/spectre.component';
import { TopgunComponent } from './components/topgun/topgun.component';
import { TranscendenceComponent } from './components/transcendence/transcendence.component';
import { ExpendablesComponent } from './components/expendables/expendables.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    OnlyNumberDirective,
    AvatarComponent,
    AvengersComponent,
    CoreComponent,
    DarkknightComponent,
    GladiatorComponent,
    GodfatherComponent,
    HungerComponent,
    InterstellarComponent,
    MatrixComponent,
    MoonwalkerComponent,
    SherlockComponent,
    SkyfallComponent,
    SpectreComponent,
    TopgunComponent,
    TranscendenceComponent,
    ExpendablesComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    TextMaskModule,
  ],
  providers: [
    CompanyService,
    HttpRequestService,
    MessageService, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
