import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sherlock',
  templateUrl: './sherlock.component.html',
  styleUrls: ['./sherlock.component.scss']
})
export class SherlockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    twttr.widgets.load();
  }

}
