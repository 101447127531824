import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interstellar',
  templateUrl: './interstellar.component.html',
  styleUrls: ['./interstellar.component.scss']
})
export class InterstellarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    twttr.widgets.load();
  }

}
